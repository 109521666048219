import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d750a676"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "measurement-banner-describe"
};
var _hoisted_2 = {
  class: "measurement-banner-title-text"
};
var _hoisted_3 = {
  class: "measurement-banner-title-text"
};
var _hoisted_4 = {
  class: "measurement-banner-title-text"
};
var _hoisted_5 = {
  class: "measurement-banner-desc-text"
};
import { onMounted, onUnmounted, ref } from "vue";
export default {
  __name: 'measurement-banner',
  setup: function setup(__props) {
    var screenHeight = ref(0);
    screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
    onMounted(function () {
      window.onresize = function () {
        return function () {
          screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
        }();
      };
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "measurement-banner",
        style: _normalizeStyle({
          height: _unref(screenHeight) + 'px'
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("reservation.measurementBannerText1")), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("reservation.measurementBannerText2")), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("reservation.measurementBannerText3")), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("reservation.measurementBannerText4")), 1)])], 4);
    };
  }
};