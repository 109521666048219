import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0aec2ac8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "reservation-measurement"
};
import MeasurementBanner from "@/views/other_module/reservation-measurement-module/measurement-banner.vue";
import OfflineServiceGuidelines from "@/views/other_module/reservation-measurement-module/offline-service-guidelines.vue";
import DigitalMeasurementIntroduce from "@/views/other_module/reservation-measurement-module/digital-measurement-introduce.vue";
import SurveyServiceIntroduction from "@/views/other_module/reservation-measurement-module/survey-service-introduction.vue";
export default {
  __name: 'reservation-measurement',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(MeasurementBanner), _createVNode(OfflineServiceGuidelines), _createVNode(DigitalMeasurementIntroduce), _createVNode(SurveyServiceIntroduction)]);
    };
  }
};