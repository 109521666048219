import request from "@/utils/request";
export default class ApiStoreFront {
  // 获取门店列表
  static getStoreFrontList(params) {
    return request({
      url: "api/v2/shop/list",
      method: "GET",
      params
    })
  }
  // 获取门店预约时间段列表
  static getBookingTimeList(params) {
    return request({
      url: "api/v2/booking/bookingData",
      method: "GET",
      params
    })
  }
  /**
   * @description 门店服务预约
   * @param {object} data
   * @param {string} data.real_name 姓名
   * @param {string} data.call 称呼
   * @param {string} data.phone 手机号
   * @param {string} data.server_date 预约服务日期
   * @param {string} data.server_clock 预约服务时间段
   * @param {string} data.address 用户详细地址
   */
  static bookingSubmit(data) {
    return request({
      url: "api/v2/booking_h5",
      method: "POST",
      data
    })
  }
}


